import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo">
     
        </div>
        <h1>
          Ring By Spring
        </h1>
        <h2>
          Coming Soon!
        </h2>
        <p>
          The new way to find your Husband or Wife at a Christian University.
        </p>

      </header>
    </div>
  );
}

export default App;
